import {
  LoaderFunctionArgs,
  useLoaderData,
  useParams,
  useRouteError,
  useRouteLoaderData,
} from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../../generic-error";
import ReviewDisplay, {
  ReviewDisplayReview,
} from "../../../../components/review-display";
import ResourceDisplay from "../../../../components/resource-display";
import ResourceService from "../../../../service/resource";
import OutputResource from "../../../../dtos/resource";
import { OutputTransmedReview } from "../../../../dtos/transmed/review";
import TransmedService from "../../../../service/transmed";
import { OutputTransmedGalleryEntry } from "../../../../dtos/transmed/gallery";
import TransmedGalleryService from "../../../../service/transmed/review";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { IMAGE_HOST } from "../../../../api/config";

import { LoaderType as TransmedLoaderType } from "..";
import { id as TransmedId } from "..";

const paramNames = { county: "county" };

export const path = `:${paramNames.county}/`;

const resourceKeys = {
  aboutLinie: "about-nonews-liniealbastra",
  aboutPeople: "about-nonews-people",
};

type LoaderType = [
  OutputTransmedReview[],
  OutputTransmedGalleryEntry[],
  { [key: string]: OutputResource }
];

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<LoaderType> {
  const reviews = new TransmedService().read.byCounty(
    params[paramNames.county]!
  );
  const gallery = new TransmedGalleryService().read.byCounty(
    params[paramNames.county]!
  );
  const resources = new ResourceService().read.byIds(
    Object.values(resourceKeys)
  );
  return [await reviews, await gallery, await resources];
}

export function Component() {
  const [reviews, gallery, resources] = useLoaderData() as LoaderType;
  const [, counties, _] = useRouteLoaderData(TransmedId) as TransmedLoaderType;

  const params = useParams();

  return (
    <section className="list content-container">
      {reviews.length === 0 ? (
        <div className="content-container">
          <div className="no-reviews">
            <div className="big-container">
              <h2 className="text-container">No reviews available</h2>
              <div className="line-container">
                <ResourceDisplay
                  resource={resources[resourceKeys.aboutLinie]}
                />
              </div>
            </div>
            <div className="image-container">
              <ResourceDisplay resource={resources[resourceKeys.aboutPeople]} />
            </div>
          </div>
        </div>
      ) : (
        <>
          <section className="gallery">
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              navigation={true}
              modules={[Navigation, Pagination]}
              className="mySwiper"
            >
              {gallery.map((entry) => (
                <SwiperSlide>
                  <img src={IMAGE_HOST + entry.location} alt={entry.location} />
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
          {counties
            .filter((county) => county.name === params[paramNames.county])
            .map((county) => (
              <>
                <h3 className="country-title">{county.title}</h3>
              </>
            ))}
          {reviews.map((review) => (
            <ReviewDisplay
              review={
                new ReviewDisplayReview(
                  review.name,
                  review.date.toLocaleDateString("en-GB", {
                    year: "numeric",
                    month: "long",
                  }),
                  review.title,
                  review.pros,
                  review.cons
                )
              }
            />
          ))}
        </>
      )}
    </section>
  );
}
Component.displayName =
  "page-oportunitati-exchanges-outgoings-continent-reviews";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName =
  "page-oportunitati-exchanges-outgoings-continent-reviews-error";
