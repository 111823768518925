import React from "react";
import {
  Link,
  Outlet,
  Route,
  useNavigate,
  useRouteError,
} from "react-router-dom";
import "./styles.css";
import GenericErrorDisplay from "../../generic-error";
import ResourceDisplay from "../../../components/resource-display";
import ResourceService from "../../../service/resource";
import OutputResource from "../../../dtos/resource";
import { useLoaderData } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

import * as PageIndex from "./page-index";
import * as PageReviews from "./page-reviews";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { OutputTransmedCounty } from "../../../dtos/transmed/county";
import TransmedService from "../../../service/transmed";
import PinMap, { MapPin, MapPinGroup } from "../../../components/pin-map";
import { IMAGE_HOST } from "../../../api/config";
import OutputTransmedCard, {
  OutputTransmedCardImagePosition,
} from "../../../dtos/transmed/card";
import TransmedCardService from "../../../service/transmed/card";

export const path = "transmed/";
export const id = "transmed";

const resourceKeys = {
  header: "transmed-page-headerTransmed",
  leftline: "transmed-page-leftline",
  locomotiva: "transmed-page-locomotiva",
  card: "transmed-page-card",
  cardVertical: "transmed-page-cardResize",
  subtitle: "transmed-page-cardSubtitle",
  title: "transmed-page-cardTitle",
  romaniaMap: "opportunities-exchanges-romania-map",
  roMoldova: "transmed-page-RomaniaMoldova",
  accountResource: "opportunities-transmed-account-resource",
  engine: "opportunities-transmed-train-engine",
  engineSmall: "opportunities-transmed-train-engine-small",
  engineText: "opportunities-transmed-train-engine-text",
};

export type LoaderType = [
  { [key: string]: OutputResource },
  OutputTransmedCounty[],
  OutputTransmedCard[]
];

export async function loader(): Promise<LoaderType> {
  const resources = new ResourceService().read.byIds(
    Object.values(resourceKeys)
  );
  const counties = new TransmedService().read.counties();
  const cards = new TransmedCardService().read.all();
  return [await resources, await counties, await cards];
}
export function Component() {
  const [resources, counties, cards] = useLoaderData() as LoaderType;
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const isMobileVagon = () => width < 650;

  return (
    <div className="page-oportunitati-transmed">
      <ResourceDisplay
        resource={resources[resourceKeys.header]}
        className="header"
      />
      <div className="mid-page">
        <div className="train">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            className="vagon-swiper"
          >
            <SwiperSlide style={{ alignItems: "flex-end" }}>
              <div
                className="connection-line"
                style={{ borderColor: "transparent" }}
              />
              <ResourceDisplay
                resource={resources[resourceKeys.engine]}
                className="engine"
              />
              {/* <ResourceDisplay
                resource={resources[resourceKeys.engineText]}
                className="engine-text"
              /> */}
              <div className="connection-line" />
            </SwiperSlide>
            {cards.map((card, index) => (
              <SwiperSlide>
                <div className="connection-line" />
                <div className="vagon-container content-container">
                  <div className="vagon">
                    <ResourceDisplay
                      resource={
                        !isMobileVagon()
                          ? resources[resourceKeys.card]
                          : resources[resourceKeys.cardVertical]
                      }
                      className="card"
                    />
                    <div
                      className="information-container"
                      style={{
                        flexDirection:
                          card.image_position ===
                          OutputTransmedCardImagePosition.top
                            ? "column"
                            : card.image_position ===
                              OutputTransmedCardImagePosition.bottom
                            ? "column-reverse"
                            : card.image_position ===
                              OutputTransmedCardImagePosition.left
                            ? "row"
                            : "row-reverse",
                      }}
                    >
                      {card.background && (
                        <div className="background">
                          <img
                            src={IMAGE_HOST + card.background}
                            alt={card.background}
                            style={{ objectPosition: card.background_position }}
                          />
                        </div>
                      )}
                      {card.image && (
                        <div
                          className="img-container"
                          style={{
                            minHeight:
                              card.image_position ===
                                OutputTransmedCardImagePosition.left ||
                              card.image_position ===
                                OutputTransmedCardImagePosition.right
                                ? "calc(100% - calc(2 * var(--gap-medium)))"
                                : "unset",
                            minWidth:
                              card.image_position ===
                                OutputTransmedCardImagePosition.top ||
                              card.image_position ===
                                OutputTransmedCardImagePosition.bottom
                                ? "calc(100% - calc(2 * var(--gap-medium)))"
                                : "unset",
                            height:
                              card.image_position ===
                                OutputTransmedCardImagePosition.top ||
                              card.image_position ===
                                OutputTransmedCardImagePosition.bottom
                                ? "40%"
                                : "unset",
                            marginBottom:
                              card.image_position ===
                              OutputTransmedCardImagePosition.bottom
                                ? "var(--gap-medium)"
                                : "unset",
                            marginTop:
                              card.image_position ===
                              OutputTransmedCardImagePosition.top
                                ? "var(--gap-medium)"
                                : "unset",
                          }}
                        >
                          <img src={IMAGE_HOST + card.image} alt={card.image} />
                        </div>
                      )}
                      <div className="text-container">
                        <h3>{card.title}</h3>
                        <div>{card.subtitle}</div>
                        {card.button_url && (
                          <Link
                            to={"/" + card.button_url}
                            className="fill-button"
                            style={{
                              padding: "var(--gap-small) var(--gap-medium)",
                            }}
                          >
                            {card.button_title ?? "Accesează"}
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="wheels">
                    <div className="left-wheel" />
                    <div className="right-wheel" />
                  </div>
                </div>
                {
                  <div
                    className="connection-line"
                    style={{
                      opacity: index !== cards.length - 1 ? "100%" : "0%",
                    }}
                  />
                }
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="connection-line" />
      </div>

      <div className="lower-page">
        <section className="content-container">
          <ResourceDisplay
            resource={resources[resourceKeys.accountResource]}
            className="account"
          />
        </section>
      </div>
      <section className="reviews">
        <PinMap
          map={IMAGE_HOST + resources[resourceKeys.roMoldova].value}
          pins={counties.map(
            (county) =>
              new MapPinGroup(
                county.title,
                [
                  new MapPin("", 3828628, () =>
                    navigate(`${county.name}/`, {
                      preventScrollReset: true,
                    })
                  ),
                ],
                county.location_x,
                county.location_y
              )
          )}
        />
        <Outlet />
      </section>
    </div>
  );
}
Component.displayName = "page-oportunitati-transmed";

export function ErrorBoundary() {
  const error = useRouteError();
  return <GenericErrorDisplay error={error} />;
}
ErrorBoundary.displayName = "page-oportunitati-transmed-error";

export const children = [
  <Route index {...PageIndex} />,
  <Route {...PageReviews} />,
];
